import React, { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
// import NotFound from "views/shared-component/404";
import Image from "helpers/Image";

// Layouts
import AuthLayouts from "layouts/AuthLayouts";
import AppLayout from "layouts/CommonLayout";



// Report & Performance Routes
const Dashboard = lazy(() => import("./views/app/dashboard/index"));

// System Configuration Routes
const UserManagement = lazy(() => import("./views/app/userAndDepartment/user"));
const DepartmentManagement = lazy(() => import("./views/app/userAndDepartment/department"));
const Role = lazy(()=>import("./views/app/userAndDepartment/role/role"))
const NotificationManagement = lazy(() => import("./views/app/notificationManagement/index"));

const MachineList = lazy(() => import("./views/app/machineList/machine"));
const SubMachineList =lazy(() => import("./views/app/machineList/subMachine"));

const MachineInspection=lazy(()=>import("./views/app/MachineInspection/machineList/index"))
const MachineSystem=lazy(()=>import("./views/app/MachineInspection/machineSystem/index"))

const Chart = lazy(()=>import("../src/views/app/chart/chart"));
const Scorecard = lazy(()=>import("../src/views/app/scorecard/scorecard"))
const Activitylog = lazy(()=>import("../src/views/app/activitylog/activitylog"))
const Profile = lazy(()=>import("../src/views/app/profile/profile"))


const Register =lazy(()=>import("../src/views/auth/register"))
const  Signin =lazy(()=>import("../src/views/auth/signin"))
const Login =lazy(()=>import("../src/views/auth/login"))
const Face = lazy(()=>import('../src/views/auth/face'))

function App() {

  return (
    <Suspense fallback={<div className="loader-img"><Image name="mainLoader2.gif" /></div>}>
    <Routes>
      <Route element={<AuthLayouts/>}>
      <Route exact path="/" element={<Login />} />
      <Route path="/Signin" element={<Signin />} />
      <Route path="/Register" element={<Register/>}/>
      <Route path='/login' element={<Login/>}/>
      <Route path='/face-login' element={<Face/>}/>

      </Route>
    </Routes>
      <Routes>
            <Route element={<AppLayout />}>
            {/* <Route path="/" element={<Dashboard />} /> */}
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/user-management" element={<UserManagement />} />
              <Route path="/department-management" element={<DepartmentManagement />} />
              <Route path="/role" element={<Role/>}/>
              <Route path="/notification-management" element={<NotificationManagement />} />
              <Route path="/machine-list" element={<MachineList />} />
              <Route path="/machine-list/sub-machine" element={<SubMachineList />} />
              <Route path="/machine-inspection" element={<MachineInspection />} />
              <Route path="/machine-inspection/machine-system" element={<MachineSystem />} />
              <Route path="/chart" element={<Chart/>} />
              <Route path="/scorecard" element={<Scorecard/>} />
              <Route path="/activitylog" element={<Activitylog/>}/>
              <Route path="/profile" element={<Profile/>}/>
            </Route>
            {/* <Route path="/user-not-found" element={<UserNotFound />} />
            <Route path="*" element={<NotFound />} /> */}
          </Routes>
       
    </Suspense>


   
          
  );
}

export default App;

