import React, { Fragment, useState, useMemo } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { Row, Col, Avatar, Layout, Menu, Popover, Badge } from 'antd';
import { CaretRightOutlined, CaretLeftOutlined } from '@ant-design/icons';
import Image from 'helpers/Image';
import "./style.css";
import { fileViewBaseUrl } from 'helpers/constants/constants';
import Scorecard from 'views/app/scorecard/scorecard';

const { Sider } = Layout;

const Sidebar = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const eqid = useParams();


  const [collapsed, setCollapsed] = useState(false);
  const [currentMenu, setCurrentMent] = useState("dashboard");

  const handleClick = (val) => {
    setCurrentMent(val.key);
    if (val?.key === "dashboard") {
      navigate("/dashboard");

    } else if (val?.key === 'userManagement') {
      navigate("/user-management");
    } else if (val?.key === "notification") {
      navigate("/notification-management");
    }
    else if (val?.key === 'module') {
      navigate("/machine-list")
    }
    else if (val?.key === 'MachineInspection') {
      navigate("/machine-inspection")
    }
    else if (val?.key === 'Chart') {
      navigate("/chart")
    }
    else if (val?.key === 'Scorecard') {
      navigate("/scorecard")
    }
    else if(val?.key === 'Activitylog'){
      navigate("/activitylog")
    }
   
  
  }

  const Settings = ["manageDepartment", "manageRoles", "rolePermission", "userManagement", "module", "dashboardSession"];

  const selectedKey = (path) => {
    if (path === "/" ||path === "/dashboard") {
      return "dashboard"
    } else if (path === "/department-management") {
      return "manageDepartment"
    } else if (path === "/notification-management") {
      return "notification"
    }
    else if (path === "/calibration/module") {
      return "module"
    }
    else if (path === "/calibration/MachineInspection") {
      return "MachineInspection"
    }
    else if (path === "/department-management") {
      return "MachineInspection"
    }
    else if (path === "/chart") {
      return "chart"
    }
    else if(path === "/scorecard"){
      return "scorecard"
    }
    else if(path === '/activitylog'){
      return "activitylog"
    }
  }

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }

  const dashSub = ["dashboard","chart","Scorecard"];


const items = [
  // getItem('Report','1', <Image name={dashSub.includes(selectedKey(location?.pathname)) ?  "dashboard-white.svg" : "dashboard.svg"} className="sidebar-tab-icon" />),[
  //   getItem('Dashboard', 'dashboard', <Image name={currentMenu === "dashboard" ? "dashboard-white.svg" : "dashboard.svg"} className="sidebar-tab-icon" />) ,
  //   getItem("Chart", 'Chart', <Image name={currentMenu === "chart" ? "dashboard-role-white.svg" : "dashboard-user.svg"} className="sidebar-tab-icon" />),
  //   getItem("Scorecard", 'Scorecard', <Image name={currentMenu === "Scorecard" ? "certificate-white.svg" : "certificate.svg"} className="sidebar-tab-icon" />)

  // ],
  getItem("MachineInspection", 'MachineInspection', <Image name={selectedKey(location?.pathname) === "MachineInspection" ? "certificate-white.svg" : "certificate.svg"} className="sidebar-tab-icon" />),
  getItem('Dashboard', 'dashboard', <Image name={currentMenu === "dashboard" ? "dashboard-white.svg" : "dashboard.svg"} className="sidebar-tab-icon" />), 
  getItem("Scorecard", 'Scorecard', <Image name={currentMenu === "Scorecard" ? "certificate-white.svg" : "certificate.svg"} className="sidebar-tab-icon" />),
  getItem("Chart", 'Chart', <Image name={currentMenu === "chart" ? "dashboard-role-white.svg" : "dashboard-user.svg"} className="sidebar-tab-icon" />),
  getItem("MachineList", 'module', <Image name={selectedKey(location?.pathname) === "module" ? "certificate-white.svg" : "certificate.svg"} className="sidebar-tab-icon" />),
  getItem('User Management', 'userManagement', <Image name={currentMenu === "userManagement" ? "dashboard-user-white.svg" : "dashboard-user.svg"} className="sidebar-tab-icon"/>),
  getItem('Notification Management', 'notification', <Image name={currentMenu === "notification" ? "dashboard-section-white.svg" : "dashboard-section.svg"} className="sidebar-tab-icon"/>),

 
 
  getItem("Activitylog" , "Activitylog",<Image name={currentMenu === "Activitylog" ? "certificate-white.svg" : "certificate.svg"} className="sidebar-tab-icon" />),
  // getItem("Profile" , "Profile",<Image name={currentMenu === "Profile" ? "certificate-white.svg" : "certificate.svg"} className="sidebar-tab-icon" />)
];


  
  // getItem('Settings', 'settings', <Image name={Settings.includes(selectedKey(location?.pathname)) ? "dashboard-settings-white.svg" : "dashboard-settings.svg"} className="sidebar-tab-icon" />, [
  //   getItem('Department Management', 'manageDepartment', <Image name={currentMenu === "manageDepartment" ? "dashboard-department-white.svg" : "dashboard-department.svg"} className="sidebar-tab-icon"/>),
  //   getItem('Portal Roles Management', 'portalRolesManagement', <Image name={currentMenu === "manageRoles" ? "dashboard-role-white.svg" : "dashboard-role.svg"} className="sidebar-tab-icon" />, [
  //     getItem('Manage Roles', 'manageRoles', <Image name={currentMenu === "manageRoles" ? "dashboard-role-white.svg" : "dashboard-role.svg"} className="sidebar-tab-icon" />),
  //     getItem('Permission management', 'rolePermission', <Image name={currentMenu === "manageDepartment" ? "dashboard-department-white.svg" : "dashboard-department.svg"} className="sidebar-tab-icon"/>),
  //   ]),
  //   getItem('User Management', 'userManagement', <Image name={currentMenu === "userManagement" ? "dashboard-user-white.svg" : "dashboard-user.svg"} className="sidebar-tab-icon"/>),
  //   getItem('Module Management', 'module', <Image name={currentMenu === "module" ? "dashboard-module-white.svg" : "dashboard-module.svg"} className="sidebar-tab-icon"/>),
  //   getItem('Dashboard Management', 'notification', <Image name={currentMenu === "notification" ? "dashboard-section-white.svg" : "dashboard-section.svg"} className="sidebar-tab-icon"/>),
  // ]),
  // getItem("Application Configuration", 'appConfiguration', <Image name={selectedKey(location?.pathname) === "appConfiguration" ? "certificate-white.svg" : "certificate.svg"} className="sidebar-tab-icon" />),






  // const items = [
  //   getItem('Dashboard', 'dashboard', <Image name={currentMenu === "dashboard" ? "dashboard-white.svg" : "dashboard.svg"} className="sidebar-tab-icon" />),
  //    getItem('Settings', 'settings', <Image name={Settings.includes(selectedKey(location?.pathname)) ? "dashboard-settings-white.svg" : "dashboard-settings.svg"} className="sidebar-tab-icon" />, [
  //     getItem('Manage Department', 'manageDepartment'),
  //     getItem('Manage Roles', 'manageRoles'),
  //     getItem('Role Permission', 'rolePermission'),
  //     getItem('User Management', 'userManagement'),
  //     getItem('Module', 'module'),
  //     getItem('Dashboard Session', 'notification'),
  //   ]),
  //   getItem("Application Configuration", 'appConfiguration', <Image name={selectedKey(location?.pathname) === "appConfiguration" ? "certificate-white.svg" : "certificate.svg"} className="sidebar-tab-icon" />),
  // ];



  const profile = () => {
    navigate("/profile");
  }
  const logout =()=> {
    navigate("/login");
  }
  const content = (
    <div className='pop-hover-content'>
      <div className='pop-profile' onClick={profile}>
        <p>Profile</p>
        {/* <Image name="profile-right-arrow.svg" /> */}
      </div>
      <hr />
      <div className='pop-logout' onClick={logout} >
        <p>Logout</p>
      </div>
    </div>
  );
  const close = (val) => {
    setCollapsed(!collapsed)

    var element = document.querySelector('.ant-ribbon.ant-ribbon-placement-end');
    if (element) {
      if (val === true) {
        element.style.right = '-20px';
      }
      else {
        element.style.right = '-8px';
      }
    }

  }
  return (
    <Fragment>
      <Badge.Ribbon text={
        !collapsed ? <CaretLeftOutlined onClick={() => close(true)} /> : <CaretRightOutlined onClick={() => close(false)} />
      }  >


        <Sider trigger={null} className={collapsed ? "sidebar-small" : "sidebar-big"} collapsible collapsed={collapsed}
        >
          <div className={collapsed ? "sidebar-logo-small txt-center" : "sidebar-logo-big txt-center"}>
            {collapsed ? <Image name="small-logo.png" className={collapsed ? "small-logo" : "logo"} onClick={() => close(false)} /> :
              <Link to="/">
                <div className="login-image slider">
                  <Image name="image1.png" className="login_logo" />
                  <p className="login-text" id="spc">Machine</p>
                  <p className="login-text1">Inspection</p>
                </div>
              </Link>}

          </div>

          <Menu
            theme="dark"
            mode="inline"
            className='sidebar-menu-item'
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['settings']}
            onClick={handleClick}
            selectedKeys={[selectedKey(location?.pathname)]}
            items={items}
          >
            {items.map((item, a) => (
              !item.children ? (
                <Menu.Item key={item.key} icon={item.icon} className='animated-menu' style={{ animationDelay: `${a * 0.2}s` }}>
                  <Link to="/dashboard">
                    {item.label}
                  </Link>
                </Menu.Item>
              ) : (
                <Menu.SubMenu key={item.key} icon={item.icon} title={item.label} className='animated-menu' style={{ animationDelay: `${a * 0.5}s` }}>
                  {item.children.map((child, e) => (
                    !child.children ? (
                      <Menu.Item key={child.key} icon={child.icon} className='animated-sub-menu' style={{ animationDelay: `${e * 0.5}s` }}>
                        <Link to={`/settings/${child.key}`}>
                          {child.label}
                        </Link>
                      </Menu.Item>
                    ) : (
                      <Menu.SubMenu key={child.key} icon={child.icon} title={child.label} className='animated-sub-menu' style={{ animationDelay: `${e * 0.5}s` }}>
                        {child.children.map((subChild, f) => (
                          <Menu.Item key={subChild.key} icon={subChild.icon} className='animated-sub-sub-menu' style={{ animationDelay: `${f * 0.5}s` }}>
                            <Link to={`/settings/${subChild.key}`}>
                              {subChild.label}
                            </Link>
                          </Menu.Item>
                        ))}
                      </Menu.SubMenu>
                    )
                  ))}
                </Menu.SubMenu>
              )
            ))}
          </Menu>

          <br /><br /><br /><br />
          <div className={collapsed ? "sidebar-Footer-small" : "sidebar-Footer-big"}>
            <div className='profile-details'>
              <Row >
                <Col md={6}>
                 <Image name="profile-pic.png" className="prof-img" />
                  {/* {loginData?.data?.profileImageUrl === null ? <Avatar size={42}>{loginData?.data?.firstName.charAt(0).toUpperCase()}</Avatar> : <Avatar src={`${fileViewBaseUrl}${encodeURIComponent(loginData?.data?.profileImageUrl)}`} size={42}></Avatar>} */}
                </Col>
                <Col md={15}>
                <p>Admin</p>
                <p>Super Admin</p>
                  {/* <p>{loginData?.data?.firstName} {loginData?.data?.lastName}</p> */}
                  {/* <h3>{loginData?.data?.roleName}</h3> */}
                </Col>
                <Col md={1}>
                  <Popover content={content} placement='left'>
                    <span className='profile-button'>
                      <Image className="dote" name="dote.svg" />
                    </span>
                  </Popover>
                </Col>
              </Row>
            </div>
          </div>
        </Sider>
      </Badge.Ribbon>
    </Fragment >
  )
}

export default Sidebar
