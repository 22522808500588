import React, { Fragment } from 'react';
import CommonSidebar from './utils/CommonSidebar';
import { Outlet } from "react-router-dom";
import { Layout } from 'antd';

const CommonLayout = () => {
   return (
      <Fragment>
         <Layout>
            <CommonSidebar />
            <Layout>
               <Outlet />
            </Layout>
         </Layout>
      </Fragment>
   );
}

export default CommonLayout;