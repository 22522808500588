


import React from 'react';
import { Row, Col, Tabs } from 'antd';
import Chart from 'react-apexcharts';
import "./styles.css";

const { TabPane } = Tabs;

const Scorecard = () => {
  const options = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [2, 2],
    },
    colors: ['#6366F1', '#A5B4FC'],
    title: {
      text: 'PM Consistency (Overall)',
      align: 'center',
      style: {
        
        color: '#fff',
        marginTop:'10px'
      },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0],
      style: {
        colors: ['#6366F1'],
      },
    },
    labels: [
      '1H21', '2H21', '1H22', '2H22', 'Oct 21', 'Nov 21', 'Dec 21', 'Jan 22',
      'Feb 22', 'Mar 22', 'Apr 22', 'May 22', 'Jun 22', 'Jul 22', 'Aug 22', 'Sep 22'
    ],
    xaxis: {
      type: 'category',
      labels: {
        style: {
          colors: '#fff',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Percentage',
        style: {
          color: '#fff',
        },
      },
      min: 0,
      max: 100,
      labels: {
        style: {
          colors: '#fff',
        },
        formatter: function (val) {
          return `${val.toFixed(0)}%`;
        },
      },
    },
    legend: {
      show: false,
    },
    grid: {
      padding: {
        bottom: 10,
      },
    },
    annotations: {
      yaxis: [
        {
          y: 100,
          strokeDashArray: 5,
          borderColor: '#000',
        },
        {
          y: 60,
          strokeDashArray: 5,
          borderColor: '#000',
        },
      ],
    },
  };

  const series = [
    {
      name: '% On Time (Overall)',
      type: 'line',
      data: [76.6, 95.5, 96.8, 95.6, 99.6, 97.7, 99.4, 85.1, 96.6, 98.5, 96.5, 100, 96.8, 99.4, 97.5, 97.6],
    },
    {
      name: '% Completion (Overall)',
      type: 'column',
      data: [60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60],
    },
  ];

  const optionsOverall = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [2, 2, 2, 2],
    },
    colors: ['#6366F1', '#A5B4FC', '#F59E0B', '#6B7280'],
    title: {
      text: 'PM Consistency (Individual Dept)',
      align: 'center',
      style: {
        color: '#fff',
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#6366F1', '#A5B4FC', '#F59E0B', '#6B7280'],
      },
    },
    labels: [
      '1H21', '2H21', '1H22', '2H22', 'Oct 21', 'Nov 21', 'Dec 21', 'Jan 22',
      'Feb 22', 'Mar 22', 'Apr 22', 'May 22', 'Jun 22', 'Jul 22', 'Aug 22', 'Sep 22'
    ],
    xaxis: {
      type: 'category',
      labels: {
        style: {
          colors: '#fff',
        },
      },
    },
    yaxis: [
      {
        title: {
          text: 'PM On Time (%)',
          style: {
            color: '#fff',
          },
        },
        min: 0,
        max: 100,
        labels: {
          style: {
            colors: '#fff',
          },
          formatter: function (val) {
            return `${val.toFixed(0)}%`;
          },
        },
      },
      {
        opposite: true,
        title: {
          text: 'PM Completion (%)',
          style: {
            color: '#fff',
          },
        },
        min: 0,
        max: 100,
        labels: {
          style: {
            colors: '#fff',
          },
          formatter: function (val) {
            return `${val.toFixed(0)}%`;
          },
        },
      },
    ],
    legend: {
      show: false,
    },
    grid: {
      padding: {
        bottom: 20,
      },
    },
  };

  const seriesOverall = [
    {
      name: '% On Time (Dept 1)',
      type: 'line',
      data: [70, 68, 100, 100, 100, 100, 100, 94.4, 86.0, 94.4, 94.4, 75.0, 97.2, 86.1, 93.9, 100],
    },
    {
      name: '% On Time (Dept 2)',
      type: 'line',
      data: [69, 96.3, 100, 100, 100, 100, 94.1, 81.3, 65.0, 65.5, 100, 100, 100, 100, 100, 100],
    },
    {
      name: '% On Time (Dept 3)',
      type: 'line',
      data: [100, 96.3, 84.9, 100, 100, 94.1, 81.3, 65.0, 71.4, 100, 100, 100, 86.1, 75.0, 100, 100],
    },
    {
      name: '% On Time (Dept 4)',
      type: 'line',
      data: [100, 100, 100, 100, 100, 100, 100, 100, 82.5, 75.0, 100, 100, 100, 100, 100, 100],
    },
    {
      name: '% Completion (Dept 1)',
      type: 'column',
      data: [60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60],
    },
    {
      name: '% Completion (Dept 2)',
      type: 'column',
      data: [60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60],
    },
    {
      name: '% Completion (Dept 3)',
      type: 'column',
      data: [60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60],
    },
    {
      name: '% Completion (Dept 4)',
      type: 'column',
      data: [60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60],
    },
  ];

  return (
    <>
    <div className='tabs-container'>
    <Tabs defaultActiveKey="1" centered style={{marginTop:'20px'}}>
      <TabPane tab="PM Consistency (Overall)" key="1">
        <div className="first-chart">
          <Chart options={options} series={series} type="line" height={350} />
          <Row justify="center" className="pm-overall-legend">
            <Col className="pm-overall-legend-item">
              <div className="pm-overall-legend-color completion-overall"></div>
              <span className="legend-text">% Completion (Overall)</span>
            </Col>
            <Col className="pm-overall-legend-item">
              <div className="pm-overall-legend-color ontime-overall"></div>
              <span className="legend-text">% On Time (Overall)</span>
            </Col>
          </Row>
        </div>
      </TabPane>
      <TabPane tab="PM Consistency (Individual Dept)" key="2">
        <div className="second-chart">
          <Chart options={optionsOverall} series={seriesOverall} type="line" height={350} />
          <Row justify="center" className="custom-legend">
          <Col className="legend-item">
            <div className="legend-color dept1"></div>
            <span className='legend-text'>% On Time (Dept 1)</span>
          </Col>
          <Col className="legend-item">
            <div className="legend-color dept2"></div>
            <span className='legend-text'>% On Time (Dept 2)</span>
          </Col>
          <Col className="legend-item">
            <div className="legend-color dept3"></div>
            <span className='legend-text'>% On Time (Dept 3)</span>
          </Col>
          <Col className="legend-item">
            <div className="legend-color dept4"></div>
            <span className='legend-text'>% On Time (Dept 4)</span>
          </Col>
        </Row>
        </div>
      </TabPane>
    </Tabs>
    </div>
    </>
  );
};

export default Scorecard;


